<template>
    <div class="b_donation section">
        <div class="container">
            <div class="section_sub_title text_center text_3xl">
                <h4>{{ $t('homepage.numbersSubtitle') }}</h4>
            </div>
        </div>

        <donation-wrapper />

        <div class="button_wrapper text_center">
            <div class="container" />
            <nuxt-link
                :to="localePath(`/contribution`)"
                class="btn btn_blue_empty"
            >
                {{ $t('homepage.numbersButton') }}
            </nuxt-link>
        </div>
    </div>
</template>
<script>
import DonationWrapper from '@/components/common/DonationWrapper';

export default {
    components: {
        DonationWrapper
    }
};
</script>
